var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitChanges)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"Password","rules":_vm.passwordRules,"custom-messages":{ required: _vm.$t('validators.required') }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t('fields.new_pass')))]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"password","type":_vm.passwordFieldType,"state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.new_pass),callback:function ($$v) {_vm.new_pass=$$v},expression:"new_pass"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"invalid-feedback",style:(_vm.feedbackStyle(_vm.$store.getters['app/currentBreakPoint']))},[_vm._v(_vm._s(validationContext.errors[0]))])])],1)]}}],null,true)}),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" "+_vm._s(_vm.$t('forms.save_changes'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"button","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){_vm.hasHistory() 
          ? _vm.$router.go(-1) 
          : _vm.$router.push({ 
          name: 'school-teachers-view', 
          params: {
                      id: _vm.school.id,
                      teacher_id: _vm.userData.id,
                    },
        })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }